import axios from "axios";
import { getToken } from "./auth";

    // export const baseURL = "http://localhost:8000";
 export const baseURL = "https://tracker-backend-admin.palmaseguros.tech";

// export const baseURL = "http://129.151.183.213";



const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
